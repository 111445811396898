import { UserRole_Enum } from '@hasura/__generated__';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import * as Sentry from '@sentry/remix';
import { isBrowser } from '~/constants';

interface TokenPayload extends JwtPayload {
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': UserRole_Enum[];
    'x-hasura-default-role': UserRole_Enum;
    'x-hasura-user-id': string;
    'x-hasura-provider-id'?: string;
    'x-hasura-role': UserRole_Enum;
  };
  sessionId: string;
}

export const setTokens = ({
  accessToken,
  refreshToken,
}: Record<'accessToken' | 'refreshToken', string>) => {
  if (!isBrowser) {
    return;
  }
  localStorage.clear();
  const decoded: TokenPayload = jwtDecode(accessToken);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem(
    'id',
    decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'],
  );
  localStorage.setItem(
    'role',
    decoded['https://hasura.io/jwt/claims']['x-hasura-role'],
  );

  const providerId =
    decoded['https://hasura.io/jwt/claims']['x-hasura-provider-id'];
  if (providerId) {
    localStorage.setItem('providerId', providerId);
  } else {
    localStorage.removeItem('providerId');
  }
};

export const getTokens = () => {
  return {
    accessToken: isBrowser ? localStorage.getItem('accessToken') : null,
    refreshToken: isBrowser ? localStorage.getItem('refreshToken') : null,
  };
};

export const getUser = () => {
  return {
    id: isBrowser ? localStorage.getItem('id') : null,
    role: isBrowser ? (localStorage.getItem('role') as UserRole_Enum) : null,
    providerId: isBrowser ? localStorage.getItem('providerId') : null,
  };
};

export const clearSession = () => {
  try {
    Sentry.setUser(null);
  } catch {
    ///pass
  }

  if (isBrowser) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('id');
    localStorage.removeItem('role');
    localStorage.removeItem('providerId');
  }
};
